import React, { useEffect } from 'react';
import { getRgbaColor, v2Colors } from '@web-for-marketing/react-core';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { OverrideTextProps, Text } from '@web-for-marketing/react-ui';
import { getDangerouslySetInnerHTML, addScriptsToDOM, removeScriptTags } from './DangerouslySetInnerHTMLHelper';
import openInNewTab from '@images/Blog/OpenInNewTab.svg';

const classes = {
    rawHTML: {
        width: '100%',
        '& p': {
            margin: 0,
        },
        '& ul': {
            margin: '2rem 0 2.2rem',
        },
        '& ol': {
            margin: '2rem 0 2.2rem',
        },
        '& li': {
            marginBottom: '0.6em',
            wordBreak: 'break-word',
        },
        '& a': {
            padding: '0.5rem 0.2rem',
            margin: '0 0.1rem',
            transition: '200ms background-color ease',
            textDecoration: 'underline',
            wordBreak: 'break-word',
            color: v2Colors.text.activeSecondary,
            '&:hover': {
                backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.03)} !important`,
            },
            '&:focus': {
                outlineColor: v2Colors.core.innovation,
                backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.06)} !important`,
            },
            '&:active': {
                outline: 'none',
                backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.05)} !important`,
            },
            '&[target="_blank"]::after': {
                content: '""',
                height: '0.8em',
                width: '0.8em',
                margin: '0 0.6rem',
                verticalAlign: 'baseline',
                display: 'inline-block',
                backgroundImage: `url(${openInNewTab})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
            '&:not([href*="www.geotab.com"]):not([href^="/"])::after': {
                content: '""',
                height: '0.8em',
                width: '0.8em',
                margin: '0 0.6rem',
                verticalAlign: 'baseline',
                display: 'inline-block',
                backgroundImage: `url(${openInNewTab})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
        },
    },
} as const;

interface BaseProps {
    html: string;
    parseLinks?: boolean;
    hideLinks?: boolean;
}

type DangerouslySetInnerHTMLProps<C extends React.ElementType> = BaseProps & OverrideTextProps<C>;

export function DangerouslySetInnerHTML<C extends React.ElementType>({
    html,
    parseLinks,
    hideLinks,
    ...other
}: DangerouslySetInnerHTMLProps<C>): JSX.Element {
    const { baseUrl } = useStaticValues();
    const { sanitizedHtml, scriptTags } = removeScriptTags(html);

    useEffect(() => {
        if (scriptTags) {
            addScriptsToDOM(scriptTags);
        }
    }, [scriptTags]);

    return (
        <Text
            component='div'
            data-testid='htmlColumn'
            variant='body1'
            dangerouslySetInnerHTML={{
                __html: getDangerouslySetInnerHTML(sanitizedHtml, baseUrl, parseLinks, hideLinks),
            }}
            css={classes.rawHTML}
            color='tertiary'
            {...other}
        />
    );
}
