import React, { useMemo } from 'react';
import { Grid, GridProps, GridSize } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';
import { CMSLink, CMSLinkProps } from '@components/CMSLink';
import { wrapperStylePackage as classes } from './CardStyle';
import { CardWrapperShadowType, CardFormatType } from '@models/card';
import { LazyLoadContainer } from '@web-for-marketing/react-ui';

interface BaseProps {
    hasBorder: boolean;
    shadowType: CardWrapperShadowType;
    isLink: boolean;
    lazyLoad?: boolean;
    rounded: boolean;
    reverse?: boolean;
    noHover?: boolean;
    customSize?: number;
    target?: string | boolean;
    ariaLabel?: string;
    href?: string;
    cardFormat: CardFormatType;
    isHidden?: boolean;
}

type CardWrapperProps<C extends React.ElementType> = BaseProps &
    Omit<CMSLinkProps, keyof BaseProps> &
    Omit<GridProps<C>, keyof BaseProps>;

export function CardWrapper<C extends React.ElementType>({
    hasBorder,
    shadowType,
    lazyLoad,
    rounded,
    href,
    isLink,
    onClick,
    onKeyDown,
    cardFormat,
    reverse,
    children,
    customSize,
    target,
    noHover,
    ariaLabel,
    className,
    isHidden,
    ...other
}: CardWrapperProps<C>): JSX.Element {
    const availableOuterContainers =
        cardFormat === CardFormatType.Tile ||
        cardFormat === CardFormatType.GalleryTile ||
        cardFormat === CardFormatType.NoContentGalleryCard;
    const availableShadowTypes =
        shadowType === CardWrapperShadowType.Always ||
        shadowType === CardWrapperShadowType.Hover ||
        shadowType === CardWrapperShadowType.None;

    const mainCardStyles = [
        classes.cardWrapper,
        className,
        (isLink || href) && !noHover ? classes.linkHover : classes.noHover,
        onClick ? classes.clickable : '',
        reverse ? classes.reverse : '',
        availableShadowTypes ? classes[`shadow-${shadowType}`] : '',
        classes[`layout-${cardFormat}`] || '',
        hasBorder ? classes.border : '',
        rounded ? classes.rounded : '',
    ];

    const itemSize = useMemo(() => {
        switch (cardFormat) {
            case 'full':
            case 'long':
            case 'split':
            case 'long-small':
                return {
                    xs: 12,
                };
            case 'single-break':
                return {
                    lg: 12,
                    md: 6,
                    xs: 12,
                };
            case 'half':
                return {
                    md: 6,
                    xs: 12,
                };
            case 'tile':
            case 'gallery-tile':
                return customSize
                    ? {
                        lg: customSize,
                        md: customSize * 2 <= 12 ? customSize * 2 : 12,
                        sm: 6,
                        xs: 12,
                    }
                    : {
                        lg: 2,
                        md: 4,
                        sm: 6,
                        xs: 12,
                    };
            case 'restrictive-four-column':
            case 'four-column':
                return {
                    lg: 3,
                    md: 6,
                    xs: 12,
                };
            case 'no-content-gallery-tile':
                return {
                    md: 4,
                    xs: 6,
                };
            case 'restrictive-height':
            case 'default':
            default:
                return customSize
                    ? {
                        lg: customSize,
                        md: customSize * 2 <= 12 ? customSize * 2 : 12,
                        xs: 12,
                    }
                    : {
                        lg: 4,
                        md: 6,
                        xs: 12,
                    };
        }
    }, [cardFormat, customSize]);

    return (
        <LazyLoadContainer
            lazyload={lazyLoad}
            item
            {...(itemSize as { [key in Breakpoint]: GridSize | undefined })}
            data-testid='cardWrapperOuterContainer'
            css={availableOuterContainers ? classes[`outerContainer-${cardFormat}`] : undefined}
        >
            {isLink && href && !onClick ? (
                <CMSLink
                    href={href}
                    target={target}
                    css={mainCardStyles}
                    data-testid='cardWrapperLinkContainer'
                    aria-label={ariaLabel}
                    tabIndex={isHidden ? -1 : undefined}
                    {...other}
                >
                    {children}
                </CMSLink>
            ) : (
                <Grid
                    container
                    data-testid='cardWrapperContainer'
                    css={mainCardStyles}
                    onClick={onClick as GridProps['onClick']}
                    onKeyDown={onKeyDown as GridProps['onKeyDown']}
                    {...other}
                >
                    {children}
                </Grid>
            )}
        </LazyLoadContainer>
    );
}
