import { v2Colors, breakpoints } from '@web-for-marketing/react-core';

const layoutStyles = {
    'layout-default': {
        flexWrap: 'wrap',
        '& > *': {
            width: '100%',
        },
    },
    'layout-restrictive-height': {
        flexWrap: 'wrap',
        flexDirection: 'column',
        '& > *': {
            width: '100%',
        },
    },
    'layout-restrictive-four-column': {
        flexWrap: 'wrap',
        flexDirection: 'column',
        '& > *': {
            width: '100%',
        },
    },
    'layout-long': {
        flexWrap: 'wrap',
        '& > *': {
            width: '100%',
        },
    },
    'layout-long-small': {
        flexWrap: 'wrap',
        '& > *': {
            width: '100%',
        },
    },
    'layout-four-column': {
        flexWrap: 'wrap',
        '& > *': {
            width: '100%',
        },
    },
    'layout-single-break': {
        flexWrap: 'wrap',
        '& > *': {
            width: '100%',
        },
    },
    'layout-full': {
        justifyContent: 'flex-start',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            flexWrap: 'wrap',
        },
    },
    'layout-split': {
        justifyContent: 'flex-start',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            flexWrap: 'wrap',
        },
    },
    'layout-half': {
        flexWrap: 'wrap',
        '& > *': {
            width: '100%',
        },
    },
    'layout-tile': {
        flexWrap: 'wrap',
        height: 'auto !important',
        '& > *': {
            width: '100%',
        },
    },
    'layout-gallery-tile': {
        padding: '5rem 1rem',
        flexWrap: 'wrap',
        height: 'auto !important',
        alignItems: 'center',
        flexGrow: 1,
        maxWidth: '100%',
        '& > *': {
            width: '100%',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            padding: '3rem 1rem',
        },
    },
    'layout-no-content-gallery-tile': {
        padding: '2rem',
        flexWrap: 'wrap',
        height: 'auto !important',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        '& > *': {
            width: '100%',
        },
    },
    'layout-gallery-card': {
        padding: '5rem 1rem',
        flexWrap: 'wrap',
        height: 'auto !important',
        alignItems: 'center',
        '& > *': {
            width: '100%',
        },
    },
    'no-content-gallery-tile': {
        padding: '4rem',
        flexWrap: 'wrap',
        height: 'auto !important',
        alignItems: 'center',
        flexGrow: 1,
        '& > *': {
            width: '100%',
        },
    },
    'imageLayout-default': {
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
    },
    'imageLayout-restrictive-height': {
        maxHeight: '26rem',
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
    },
    'imageLayout-restrictive-four-column': {
        maxHeight: '26rem',
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
    },
    'imageLayout-long': {
        maxHeight: '40rem',
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
    },
    'imageLayout-long-small': {
        maxHeight: '20rem',
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
    },
    'imageLayout-single-break': {
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
    },
    'imageLayout-four-column': {
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
    },
    'imageLayout-full': {
        borderTopRightRadius: '0.6rem',
        borderBottomRightRadius: '0.6rem',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            borderTopLeftRadius: '0.6rem !important',
            borderBottomLeftRadius: '0 !important',
            borderTopRightRadius: '0.6rem !important',
            borderBottomRightRadius: '0 !important',
        },
    },
    'imageLayout-split': {
        borderTopRightRadius: '0.6rem',
        borderBottomRightRadius: '0.6rem',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            borderTopLeftRadius: '0.6rem !important',
            borderBottomLeftRadius: '0 !important',
            borderTopRightRadius: '0.6rem !important',
            borderBottomRightRadius: '0 !important',
        },
    },
    'imageLayout-gallery-tile': {
        width: '40%',
        maxHeight: '100%',
        margin: 'auto',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            maxWidth: '8rem',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            maxWidth: '6rem',
        },
    },
    'imageLayout-no-content-gallery-tile': {
        maxHeight: '100%',
        margin: 'auto',
        objectFit: 'contain !important' as 'contain',
        maxWidth: '15rem',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            maxWidth: '12rem',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            maxWidth: '10rem',
        },
    },
    'pictureLayout-full': {
        maxWidth: '48.718%',
        height: '100%',
        position: 'absolute',
        right: 0,
        minHeight: 'auto !important',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            overflow: 'hidden',
            position: 'static',
            minWidth: '100%',
            maxHeight: '50%',
        },
    },
    'pictureLayout-split': {
        maxWidth: '33%',
        height: '100%',
        position: 'absolute',
        right: 0,
        minHeight: 'auto !important',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            overflow: 'hidden',
            position: 'static',
            minWidth: '100%',
            maxHeight: '50%',
            minHeight: '22rem !important',
        },
    },
    'pictureLayout-gallery-tile': {
        marginBottom: '2rem',
        display: 'flex',
        minHeight: '7rem',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            marginBottom: '1rem',
        },
    },
    'pictureLayout-no-content-gallery-tile': {
        minHeight: '15rem',
        display: 'flex',
    },
    'pictureLayout-long': {
        minHeight: 'auto !important',
    },
    'pictureLayout-long-small': {
        minHeight: 'auto !important',
    },
    'innerLayout-full': {
        width: '50%',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            width: 'auto !important',
        },
    },
    'innerLayout-split': {
        width: '66%',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            width: 'auto !important',
        },
    },
    'outerContainer-tile': {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            flexDirection: 'column',
        },
    },
    'outerContainer-gallery-tile': {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            flexDirection: 'column',
        },
    },
    'outerContainer-no-content-gallery-tile': {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            flexDirection: 'column',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'block',
        },
    },
} as const;

const paddingStyles = {
    'padding-tiny': {
        padding: '1rem',
    },
    'padding-small': {
        padding: '2rem 2rem 3rem',
    },
    'padding-medium': {
        padding: '3rem 3rem 4rem',
    },
    'padding-medium-floating-button': {
        padding: '3rem 3rem 4rem',
    },
    'padding-medium-alt': {
        padding: '3rem 5rem',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            padding: '1rem 2rem',
        },
    },
    'padding-large': {
        padding: '5rem',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            padding: '2rem',
        },
    },
} as const;

const shadowStyles = {
    'shadow-none': {
        boxShadow: 'none',
    },
    'shadow-hover': {
        boxShadow: '0 0 0 rgba(102,120,140,0.1), 0 0 0 rgba(102,120,140,0.1)',
        '&:hover': {
            boxShadow: '0 0 .6rem rgba(102,120,140,0.1), 0 1rem 1rem rgba(102,120,140,0.1)',
        },
        '&:focus': {
            boxShadow: '0 0 .6rem rgba(102,120,140,0.1), 0 1rem 1rem rgba(102,120,140,0.1)',
        },
    },
    'shadow-always': {
        boxShadow: '0 0 .6rem rgba(102,120,140,0.1), 0 1rem 1rem rgba(102,120,140,0.1)',
    },
} as const;

const layeredStyles = {
    'layered-link': {
        width: 'auto !important',
        display: 'inline-flex',
        '&:hover': {
            backgroundColor: `${v2Colors.core.cloud} !important`,
        },
    },
    'layered-title': {
        marginBottom: '3rem !important',
    },
    'layered-subtitle': {
        marginBottom: '0 !important',
    },
    'layered-body': {
        marginBottom: '3rem !important',
    },
} as const;

const ctaStyles = {
    'cta-title': {
        marginBottom: '1rem',
    },
    'cta-link': {
        width: 'auto !important',
        display: 'inline-flex',
        '&:hover': {
            backgroundColor: `${v2Colors.core.cloud} !important`,
        },
    },
    'cta-button': {
        width: 'auto !important',
        minHeight: 0,
    },
    'cta-button-container': {
        marginBottom: 0,
    },
    'cta-alt-link': {
        width: 'auto !important',
        display: 'inline-flex',
        '&:hover, &:focus': {
            backgroundColor: `${v2Colors.core.cloud} !important`,
            textDecoration: 'underline',
        },
        transform: 'none !important',
        position: 'static !important' as 'static',
    },
    'cta-alt-button-container': {
        marginBottom: 0,
        width: '100%',
        flex: 1,
    },
    'cta-tags': {
        width: 'auto !important',
        display: 'inline',
        padding: '0.25rem 0.5rem 0.25rem 0rem',
        borderRadius: '0.6rem',
        transition: 'background-color 300ms ease',
        '&:hover': {
            backgroundColor: `${v2Colors.core.cloud} !important`,
            textDecoration: 'underline',
        },
        '&:focus': {
            outlineColor: v2Colors.core.innovation,
            backgroundColor: `${v2Colors.core.cloud} !important`,
            textDecoration: 'underline',
        },
    },
    'cta-tags-no-link': {
        width: 'auto !important',
        display: 'inline',
        padding: '0.25rem 0.5rem 0.25rem 0rem',
    },
} as const;

const imageStyles = {
    image: {
        height: '100%',
    },
    picture: {
        height: 'auto',
        minHeight: '26rem',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            minHeight: '20rem',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            minHeight: '22rem',
        },
    },
    overlayIcon: {
        padding: '2.5rem',
        position: 'absolute',
        top: '0',
        left: '50%',
        transform: 'translate(-50%, 50%)',
    },
    'image-reverse': {
        borderTopLeftRadius: '0.6rem !important',
        borderBottomLeftRadius: '0.6rem !important',
        borderTopRightRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            borderTopLeftRadius: '0.6rem !important',
            borderBottomLeftRadius: '0 !important',
            borderTopRightRadius: '0.6rem !important',
            borderBottomRightRadius: '0 !important',
        },
    },
    'image-notRounded': {
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
        borderTopRightRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
    },
    'picture-reverse': {
        left: '0 !important',
    },
} as const;

export const bodyTypeStylePackage = {
    contentContainer: {
        '& > *:not(:last-child)': {
            width: '100%',
            marginBottom: '1rem',
        },
        '& svg': {
            marginRight: '1rem',
            verticalAlign: 'middle',
        },
    },
    centered: {
        '& > *': {
            textAlign: 'center',
        },
    },
    bold: {
        fontWeight: 700,
    },
    fullSpanLink: {
        outline: 'none',
        '&::after': {
            position: 'absolute',
            cursor: 'pointer',
            inset: 0,
            content: '""',
        },
    },
    ...paddingStyles,
    ...layoutStyles,
    ...layeredStyles,
    ...ctaStyles,
} as const;

export const contentStylePackage = {
    ...imageStyles,
    ...layoutStyles,
} as const;

export const wrapperStylePackage = {
    cardWrapper: {
        position: 'relative',
        outline: 'none',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: '#ffffff',
        transition: 'transform ease 500ms, box-shadow ease 500ms',
        '&:focus, &:focus-within': {
            outline: `1px solid ${v2Colors.core.innovation}`,
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            height: 'auto !important',
        },
    },
    linkHover: {
        '&:hover': {
            transform: 'translateY(-1rem)',
        },
    },
    noHover: {
        '&:hover': {
            transform: 'none !important',
        },
    },
    clickable: {
        cursor: 'pointer',
    },
    border: {
        outline: 'none',
        border: '1px solid #DFE3EA',
        '&:focus': {
            borderColor: v2Colors.core.innovation,
            outline: 'none',
        },
    },
    rounded: {
        borderRadius: '1rem',
    },
    reverse: {
        justifyContent: 'flex-end !important',
    },
    ...layoutStyles,
    ...shadowStyles,
} as const;
