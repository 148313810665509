import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/pt-br';
import { getDayJsCodeByLanguageCode } from '@helpers/languages';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { LanguageCode } from '@models/languageCode';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

//the list of dayjs supported locales https://unpkg.com/dayjs@1.11.7/locale.json

export function getFormattedLocalDateTime(utcDateTime, format = 'MM/DD/YYYY hh:mm A', language = LanguageCode.en) {
    if (language !== LanguageCode.en) {
        const dayjsCode = getDayJsCodeByLanguageCode(language);
        return dayjs.utc(utcDateTime).local().locale(dayjsCode).format(format);
    } else {
        return dayjs.utc(utcDateTime).local().format(format);
    }
}

export function getFormattedDateTime(utcDateTime, format = 'MM/DD/YYYY hh:mm A') {
    let localTime = dayjs.utc(utcDateTime).local();

    if (isTodayDate(localTime)) {
        return 'Today';
    } else if (isYesterdayDate(localTime)) {
        return 'Yesterday';
    } else {
        return dayjs(localTime).format(format);
    }
}

export function isTodayDate(dateTime) {
    return dayjs(dateTime).isToday();
}

export function isYesterdayDate(dateTime) {
    return dayjs(dateTime).isYesterday();
}
